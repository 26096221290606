<template>
    <div class="img-thumbnail text-center overflow-hidden" style="width: 150px; height: 90px;">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"></loading>

        <img :src="thumbnail" v-if="thumbnail" class="img-fluid text-center m-auto" style="max-width: 150px; max-height: 90px;" />
        <vs-icon :class="'mt-3 text-muted d-block fa fa-3x ' + $globalFunctions.getFileTypeIcon(node.content.mimeType)" style="max-width: 150px; max-height: 90px;" v-if="node.content && !thumbnail"></vs-icon>
        <vs-icon icon="folder" size="60px" :class="'mt-3 text-muted d-block fa fa-3x fa-folder'" style="max-width: 150px; max-height: 90px;" v-if="!node.content && !thumbnail"></vs-icon>

    </div>
</template>

<script>
  import Vue from 'vue';

    export default {
        name: "ThumbnailImage",
        components: {

        },
        props: {
            node: Object
        },
        data() {
            return {
                thumbnail: null,
                isLoading: false
            }
        },

        mounted() {
            const _this = this;

            console.log("nodeId", _this.node);

            _this.isLoading = true;
            _this.getThumbnail();
        },

        computed: {},

        methods: {


            getThumbnail() {
                const _this = this;
                console.log("id", _this.node.id);


              Vue.prototype.$http.get('https://dokumenti.sopotniki.org/alfresco/s/api/node/workspace/SpacesStore' + '/' + _this.node.id + '/content/thumbnails', {
                    method: 'GET',
                })
                    .then((res) => {
                        // console.log(res);
                        _this.thumbnail = 'https://dokumenti.sopotniki.org' + res.data[0].url
                        _this.isLoading = false;

                    })
                    .catch((error) => {
                        // console.error(error);
                        _this.isLoading = false;

                    });


            },

            getFilePreview() {
                const _this = this;

              Vue.prototype.$http.get('https://dokumenti.sopotniki.org/alfresco/s/api/node/workspace/SpacesStore/' + _this.node.id + '/content/thumbnails/pdf?c=force', {
                    method: 'GET',
                })
                    .then((res) => {
                        // console.log(res);

                        _this.getThumbnail();
                    })
                    .catch((error) => {
                        // console.error(error);
                        _this.isLoading = false;

                        // Vue.$toast.open({
                        //     message: this.$t('text.documentDetails.documentPreviewNotAvailableToast.message'),
                        //     type: 'error',
                        //     position: 'top-right'
                        // });

                    });
            },
        }
    }
</script>
