<template>

<div>
    <div @click.stop="onNodeClick">

        <div :class="(getActiveNode.id === node.entry.id ? 'nodeItem active' : 'nodeItem')">
            <vs-icon icon="folder_open" v-if="node.entry.isFolder && isOpen && !isLoading"></vs-icon>
            <vs-icon icon="folder" v-if="node.entry.isFolder && !isOpen && !isLoading"></vs-icon>
            <vs-icon icon="hourglass_full" v-if="isLoading"></vs-icon>

            {{node.entry.name}}
        </div>

        <div v-for="(item, index) in nodes" :key="item.entry.id" v-if="item.entry.isFolder" class="pl-3">
            <FileBrowserMenuItem :node="item" :ref="'node_' + item.entry.id" :key="'node_' + item.entry.id" v-if="item.entry.isFolder"></FileBrowserMenuItem>
        </div>
    </div>
</div>

</template>

<script>

  import Vue from 'vue';

    export default {
        name: "FileBrowserMenuItem",
        components: {
        },
        props: {
          node: Object
        },
        data() {
            return {
                rootId: null,
                openNode: null,
                isOpen: false,
                nodes: [],
                isLoading: false
            }
        },

        mounted() {
            const _this = this;
            console.log("ID ----> ", _this.node.entry);
        },

        computed: {
          getActiveNode() {
            const _this = this;

            return _this.$store.getters.getActiveNode;
          }
        },

        watch: {
            getActiveNode: function () {
                const _this = this;
                if(_this.node.entry.id === _this.$store.getters.getActiveNode.id) {
                    _this.getChildrenNodes();
                }
            }
        },

        methods: {
            onNodeClick() {
                const _this = this;


                if(_this.isOpen) {
                    console.log("isOpen");
                    // _this.isOpen = false;

                    if(_this.node.entry === _this.$store.getters.getActiveNode) {
                        console.log("here1");
                        _this.isOpen = false;
                        _this.$store.dispatch('setActiveNodeContent', null);
                    } else {
                        console.log("here2");
                        _this.isLoading = true;
                        _this.nodes = [];
                        _this.$store.dispatch('setActiveNode', _this.node.entry);
                        _this.getChildrenNodes();
                    }

                } else {
                    console.log("not Open");
                    _this.isLoading = true;
                    _this.$store.dispatch('setActiveNode', _this.node.entry);
                    _this.getChildrenNodes();
                }
            },
            getChildrenNodes() {
                const _this = this;

                console.log("HERE!!!!");

                Vue.prototype.$http.get('https://dokumenti.sopotniki.org/alfresco/api/-default-/public/alfresco/versions/1/' + 'nodes/' + _this.$store.getters.getActiveNode.id + '/children')
                    .then((res) => {
                        console.log("res.data ", res.data);
                        _this.isLoading = false;
                        _this.nodes = res.data.list.entries;
                        _this.$store.dispatch('setActiveNodeContent', res.data.list.entries);
                        _this.isOpen = true;
                    })
                    .catch((error) => {
                        console.error(error);
                        _this.isLoading = false;
                    });

            },

        }
    }
</script>
