<template>
  <div>

    <!--        <loading :active.sync="isLoadingPreview"-->
    <!--                 :can-cancel="false"-->
    <!--                 :is-full-page="false"></loading>-->


    <div>
      <div class="row">
        <div class="col-md-8">


          <div class="card p-0" v-if="getPreviewIframeContent">

            <div class="card-body bg-light"
                 style="max-height: 1022px; overflow-y: scroll; padding-top: 20px; padding-bottom: 20px;"
                 v-if="$globalFunctions.getFileTypeFromMimeType(nodeData.content.mimeType) !== 'image' && $globalFunctions.getFileTypeFromMimeType(nodeData.content.mimeType) !== 'video' && $globalFunctions.getFileTypeFromMimeType(nodeData.content.mimeType) !== 'audio'">
              <iframe :src="getPreviewIframeContent" width="100%" height="500px" style="border: 0px;"></iframe>
<!--              <pdf-->
<!--                v-for="i in numPages"-->
<!--                :key="i"-->
<!--                :src="URL.createObjectURL(getPreviewIframeContent)"-->
<!--                :page="i"-->
<!--                :rotate="0"-->
<!--                style="width: 100%; height: 100%; margin-bottom: 10px"-->
<!--              ></pdf>-->
            </div>

            <div class="card-body bg-light" style="max-height: 1022px; overflow-y: auto;"
                 v-if="$globalFunctions.getFileTypeFromMimeType(nodeData.content.mimeType) == 'video' || $globalFunctions.getFileTypeFromMimeType(nodeData.content.mimeType) === 'audio'">
              <iframe v-if="getPreviewIframeContent" :src="getPreviewIframeContent"
                      frameborder="0"
                      width="100%" height="1100" allowfullscreen webkitallowfullscreen></iframe>
            </div>

            <div class="card-body bg-light" style="max-height: 1022px; overflow-y: auto;"
                 v-if="$globalFunctions.getFileTypeFromMimeType(nodeData.content.mimeType) === 'image'">
              <img :src="getPreviewIframeContent" class="img-responsive"
                   style="width: auto; max-width: 100%;"/>
            </div>

          </div>
          <div v-else>


            <div class="alert alert-info mt-0">
              <em><i class="fa fa-exclamation-triangle"></i>
                Predogled ni na voljo</em>
            </div>


          </div>
        </div>
        <div class="col-md-4">

          <div v-if="nodeData">
            <a class="btn btn-primary btn-sm text-white d-block"
               @click="downloadFile()"><i
              class="fa fa-download"></i> Prenesi</a>

            <div class="col-md-12 metadataSidebar p-0" ng-if="nodeData" :key="nodeData.id">
              <div class="mt-3 p-2 bg-light">
                <div class="row mb-3">
                  <div class="col-md-12">
                    <h5 class="mt-1">Podatki o dokumentu</h5>
                  </div>
                </div>
                <dl class="row" id="cm:title">
                  <dt class="col-sm-3">Naslov</dt>
                  <dd class="col-sm-9">{{nodeData.properties ? nodeData.properties["cm:title"] :
                    ''}}
                  </dd>
                </dl>
                <dl class="row" id="id">
                  <dt class="col-sm-3">Id</dt>
                  <dd class="col-sm-9">{{nodeData.id}}</dd>
                </dl>
                <dl class="row" id="rma:origionalName">
                  <dt class="col-sm-3">Originalno ime</dt>
                  <dd class="col-sm-9">{{(nodeData.properties &&
                    nodeData.properties["rma:origionalName"]) ?
                    nodeData.properties["rma:origionalName"] : '/'}}
                  </dd>
                </dl>
                <dl class="row" id="cm:author" v-if="nodeData.properties">
                  <dt class="col-sm-3">Avtor</dt>
                  <dd class="col-sm-9">{{nodeData.properties["cm:author"]}}</dd>
                </dl>
                <dl class="row" id="cm:description" v-if="nodeData.properties">
                  <dt class="col-sm-12">Opis</dt>
                  <dd class="col-sm-12">{{nodeData.properties["cm:description"]}}
                  </dd>
                </dl>
                <dl class="row" id="content.mimeTypeName" v-if="nodeData.content">
                  <dt class="col-md-12">Vrsta</dt>
                  <dd class="col-md-12">{{nodeData.content.mimeTypeName}}</dd>
                </dl>
                <dl class="row" id="content.sizeInBytes" v-if="nodeData.content">
                  <dt class="col-md-12">Velikost</dt>
                  <dd class="col-md-12">{{nodeData.content.sizeInBytes | prettyBytes}}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>


  </div>

</template>


<script>
  import Vue from 'vue';
  import pdf from 'vue-pdf'

  export default {
    name: 'DocumentDetailsMetadata',
    props: [
      "node"
    ],
    components: {
      Vue,

      pdf
    },
    data() {
      return {
        nodeData: {},
        isLoading: true,
        isLoadingPreview: false,
        fileBlob: null,
        filePreviewBlob: null,
        renditionRequests: 0,
        page: 1,
        numPages: 0,
        rotate: 0,
      };
    },
    mounted() {

      // this.getNode(node.id);

    },
    computed: {
      getPreviewIframeContent() {
        const _this = this;

        if (_this.nodeData && _this.filePreviewBlob !== null) {

          // if (_this.$globalFunctions.getFileTypeFromMimeType(_this.nodeData.content.mimeType) !== 'image' && _this.$globalFunctions.getFileTypeFromMimeType(_this.nodeData.content.mimeType) !== 'video' && _this.$globalFunctions.getFileTypeFromMimeType(_this.nodeData.content.mimeType) !== 'audio') {
          //   pdf.createLoadingTask(window.URL.createObjectURL(_this.filePreviewBlob)).then(pdf => {
          //     _this.numPages = pdf.numPages;
          //   });
          // }

          return window.URL.createObjectURL(_this.filePreviewBlob);
        }
      }
    },
    methods: {

      async getNode(nodeId) {
        const _this = this;

        _this.axiosData = [];

        let requestUrl = null;
        if (_this.location === 'cache') {
          requestUrl = _this.host + 'alfresco/api/-default-/public/alfrescocache/versions/1/' + 'nodes/';
        } else {
          requestUrl = 'https://dokumenti.sopotniki.org/alfresco/api/-default-/public/alfresco/versions/1/' + 'nodes/';
        }

        await Vue.prototype.$http.get(requestUrl + nodeId)
          .then((res) => {

            _this.nodeOriginalData = res.data.entry;
            _this.nodeData = res.data.entry;


            _this.getFileBlob();


            let types = _.filter(_this.$store.getters.getAlfrescoDictionary, function (v, k) {

              if (_this.nodeData.aspectNames.indexOf(v.name) > -1 && v.name.substring(0, 3) === 'EH:') {
                return true
              } else {
                return false
              }
            });

            _this.nodeData.types = types;
            console.log("types", types);


            _this.isLoading = false;
            console.log("node data: ", _this.nodeData);


            if (!_this.deleted) {
              if (_this.$globalFunctions.getFileTypeFromMimeType(_this.nodeData.content.mimeType) === 'pdf' ||
                _this.$globalFunctions.getFileTypeFromMimeType(_this.nodeData.content.mimeType) === 'image' ||
                _this.$globalFunctions.getFileTypeFromMimeType(_this.nodeData.content.mimeType) === 'audio' ||
                _this.$globalFunctions.getFileTypeFromMimeType(_this.nodeData.content.mimeType) === 'video') {
                _this.showPreview(false);
                _this.isLoading = false;
              } else {
                _this.getFilePreview();
                _this.isLoading = false;
                _this.isLoadingPreview = true;
              }
            }

            _this.$forceUpdate();

            //console.log(_this.globalFunctions.getFileTypeIcon(_this.nodeData.content.mimeType));

          })
          .catch((error) => {
            console.error(error);

            this.isLoading = false;
          });


      },

      displayReadableCodeListValues() {
        const _this = this;

        _.each(_this.allMetadataOptions, function (val, key) {
          let property = val.property;

          if (_this.nodeData.properties[property]) {
            _this.nodeData.properties[property] = _.find(_this.allMetadataOptions[key].body, {id: _this.nodeData.properties[property]}).description;
          }
        });

      },

      getFileBlob() {
        const _this = this;

        this.isLoading = true;

        let requestUrl = null;
        if (_this.location === 'cache') {
          requestUrl = _this.host + 'alfresco/api/-default-/public/alfrescocache/versions/1/' + 'nodes/';
        } else {
          requestUrl = 'https://dokumenti.sopotniki.org/alfresco/api/-default-/public/alfresco/versions/1/' + 'nodes/';
        }

        Vue.prototype.$http.get(requestUrl + _this.nodeData.id + '/content?attachment=true', {
          method: 'GET',
          responseType: 'blob' //Force to receive data in a Blob Format
        })

          .then((res) => {

            console.log("RESPONSE ", res);


            this.fileBlob = res.data;
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);

            this.isLoading = false;
          });

      },

      getFilePreview() {
        const _this = this;

        Vue.prototype.$http.get('https://dokumenti.sopotniki.org/alfresco/s/api/node/workspace/SpacesStore' + '/' + _this.nodeData.id + '/content/thumbnails/pdf?c=force', {
          method: 'GET',
        })
          .then((res) => {
            console.log(res);


            _this.fileBlob = res.data;
            _this.showPreview(true);
          })
          .catch((error) => {
            console.error(error);
            _this.isLoadingPreview = false;

            // Vue.$toast.open({
            //   message: this.$t('text.documentDetails.documentPreviewNotAvailableToast.message'),
            //   type: 'error',
            //   position: 'top-right'
            // });

          });
      },

      showPreview(showFromRenditions) {
        const _this = this;

        let getPdfPreview = "";


        if (showFromRenditions) {
          getPdfPreview = 'https://dokumenti.sopotniki.org/alfresco/api/-default-/public/alfresco/versions/1/' + 'nodes/' + _this.nodeData.id + '/renditions/pdf/content?attachment=true&placeholder=false';
        } else {
          getPdfPreview = 'https://dokumenti.sopotniki.org/alfresco/api/-default-/public/alfresco/versions/1/' + 'nodes/' + _this.nodeData.id + '/content?attachment=true';
        }


        Vue.prototype.$http.get(getPdfPreview, {
          method: 'GET',
          responseType: 'blob' //Force to receive data in a Blob Format
        })

          .then((res) => {
            console.log("RES", res.data);

            // if(this.showLoadTimes) {
            //     _this.axiosData.push({
            //         url: res.url,
            //         time: res.responseTime
            //     });
            // }

            _this.filePreviewBlob = res.data;
            _this.isLoading = false;
            _this.isLoadingPreview = false;
          })
          .catch((error) => {
            console.error(error);

            _this.isLoading = false;
          });

      },

      downloadFile() {
        const _this = this;

        this.isLoading = true;

        const url = window.URL.createObjectURL(_this.blobToFile(_this.fileBlob, _this.nodeData.name));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', _this.nodeData.name);
        link.setAttribute('type', _this.nodeData.content.mimeType);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.isLoading = false;
      },

      blobToFile(theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
      },

    },
  };
</script>
