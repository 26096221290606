<template>
  <div id="documentSystemContainer">
    <h1>Dokumentni sistem</h1>

    <div>
<!--            <iframe src="https://dokumenti.sopotniki.org/share" style="border: 0px; width: 100%; height: 900px;" sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation allow-popups"></iframe>-->


        <div class="flex mb-10 mt-10">
          <div class="w-1/4">
            <div v-for="(item, index) in nodes" :key="item.entry.id" v-if="item.entry.isFolder">
              <FileBrowserMenuItem :node="item" :ref="'node_' + item.entry.id"
                                   :key="'node_' + item.entry.id"
                                   v-if="item.entry.isFolder"></FileBrowserMenuItem>
            </div>
          </div>
          <div class="w-3/4">
            <div v-if="activeNodeContent">
              <div class="d-block p-2 border-bottom listItem"
                   v-for="(item, index) in activeNodeContent.list.entries"
                   :key="'list' + item.entry.id"
                   v-if="item.entry.isFolder || item.entry.isFile"
                   @click="onNodeItemClick(item)">
                <div class="preview float-left mr-2">
                  <ThumbnailImage :node="item.entry" :key="'thumbnail' + item.entry.id"
                                  v-if="item.entry"/>
                </div>
                <div class="details">
                  <h5 class="text-break" style="font-size: 1.1em">{{item.entry.name}}</h5>
                  <div style="font-size: 0.9em">
                    <div class="description" v-if="item.entry.properties">
                      <span
                        v-if="item.entry.properties['cm:description']">{{item.entry.properties['cm:description']}}</span>
                      <em v-if="!item.entry.properties['cm:description']" class="text-muted">/</em>
                    </div>
                    <div v-if="!item.entry.properties">
                      <em class="text-muted">/</em>
                    </div>
                    <div>
                      <div class="lastChange float-left"><small class="text-muted">zadnja sprememba:</small>
                        {{moment(item.entry.modifiedAt).local().format('DD. MM. YYYY HH:mm:ss')}}
                      </div>
                      <div class="size float-left ml-4" v-if="item.entry.content"><small
                        class="text-muted">velikost:</small>
                        {{item.entry.content.sizeInBytes | prettyBytes}}
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>

              <div class="row"
                   v-if="activeNodeContent && activeNodeContent.list.pagination.totalItems > serverParams.perPage">
                <div class="col-md-12 pt-3 bg-light">
                  <b-pagination
                    class="list-inline mx-auto justify-content-center"
                    v-model="serverParams.page"
                    :total-rows="activeNodeContent.list.pagination.totalItems"
                    :per-page="serverParams.perPage"
                    limit="10"
                    @change="onPageChange"
                  >
                    <template v-slot:first-text><span><i
                      class="fas fa-fast-backward"></i></span></template>
                    <template v-slot:prev-text><span><i
                      class="fas fa-step-backward"></i></span></template>
                    <template v-slot:next-text><span><i
                      class="fas fa-step-forward"></i></span></template>
                    <template v-slot:last-text><span><i
                      class="fas fa-fast-forward"></i></span></template>
                  </b-pagination>
                </div>
              </div>

            </div>
            <div v-if="activeNodeContent === null || activeNodeContent.list.entries.length === 0">
              <div class="text-center m-3 alert alert-secondary">
                <small class="muted">Ni vsebine za prikaz</small>
              </div>
            </div>
          </div>
        </div>
      </div>

    <vs-popup :title="clickedNode ? clickedNode.name : ''" :active.sync="popupActive">

      <div class="row">
        <div class="col-md-12 mb-0">

          <NodeDetails
            v-if="clickedNode"
            ref="node-details"
            :node="clickedNode"
          ></NodeDetails>
        </div>
      </div>


      <div slot="modal-footer" class="w-100">
        <b-button
          id="headerCloseModalButton1"
          variant="primary"
          class="float-right"
          @click="closeModal">
          zpri
        </b-button>
      </div>


    </vs-popup>

  </div>
</template>

<script>
  import Vue from 'vue';
  import ThumbnailImage from "../../components/Sopotniki/documentSystem/ThumbnailImage";
  import FileBrowserMenuItem from "../../components/Sopotniki/documentSystem/FileBrowserMenuItem";
  import NodeDetails from "../../components/Sopotniki/documentSystem/NodeDetails";


  export default {
    name: 'DokumentniSistem',

    components: {
      ThumbnailImage,
      FileBrowserMenuItem,
      NodeDetails
    },

    data() {
      return {
        rootId: null,
        nodes: [],
        clickedNode: null,
        activeNodeContent: null,
        popupActive: false,
        serverParams: {
          page: 1,
          perPage: 45
        },
      }
    },

    mounted() {
      const _this = this;

      // _this.getRoot();
      _this.login();
    },

    computed: {
      getActiveNode() {
        const _this = this;
        _this.serverParams.page = 1;
        return _this.$store.getters.getActiveNode;
      }
    },

    watch: {
      getActiveNode: function () {
        const _this = this;
        _this.getActiveNodeContent();
      }
    },

    methods: {
      async login() {
        const _this = this;

        let authData = {
          "userId": 'admin',
          "password": 'admin'
        }

        await Vue.prototype.$http.post('https://dokumenti.sopotniki.org/alfresco/api/-default-/public/authentication/versions/1/' + 'tickets', authData)
          .then((res) => {
            console.log(res);
            this.isLoading = false;

            console.log("RESSS", res);

            let alfrescoUser = {
              'authData': authData,
              'ticket': res.data.entry.id
            };
            sessionStorage.setItem("alfrescoUserInfo", JSON.stringify(alfrescoUser));
            _this.getRoot();

          })
          .catch((error) => {
            console.error(error);


            this.error = error.response.data.error.errorKey;

            this.isLoading = false;
          });

      },
      getRoot() {
        const _this = this;

        //console.log("HERE!!!!!!!", btoa(JSON.parse(sessionStorage.getItem("alfrescoUser")).ticket));

        // Vue.prototype.$http.defaults.headers.common['authorization'] = "Basic " + btoa(sessionStorage.getItem("alfrescoUserInfo") ? JSON.parse(sessionStorage.getItem("alfrescoUserInfo")).ticket : null);


        Vue.prototype.$http.get('https://dokumenti.sopotniki.org/alfresco/api/-default-/public/alfresco/versions/1/' + 'nodes/-root-')
          .then((res) => {

            _this.rootId = res.data.entry.id;
            _this.$store.dispatch('setActiveNode', res.data.entry);
            _this.getChildrenNodes(res.data.entry.id);
          })
          .catch((error) => {
            console.error(error);
          });

      },
      getChildrenNodes(parentId) {
        const _this = this;

        Vue.prototype.$http.get('https://dokumenti.sopotniki.org/alfresco/api/-default-/public/alfresco/versions/1/' + 'nodes/' + parentId + '/children?where=(isFolder%3Dtrue)&maxItems=1000')
          .then((res) => {

            _this.nodes = res.data.list.entries;
          })
          .catch((error) => {
            console.error(error);
          });
      },

      getActiveNodeContent() {
        const _this = this;

        Vue.prototype.$http.get('https://dokumenti.sopotniki.org/alfresco/api/-default-/public/alfresco/versions/1/' + 'nodes/' + _this.$store.getters.getActiveNode.id + '/children', {
          params: {
            skipCount: (_this.serverParams.page - 1) * _this.serverParams.perPage,
            maxItems: _this.serverParams.perPage,
            include: 'properties'
          }
        })
          .then((res) => {
            _this.activeNodeContent = res.data;
          })
          .catch((error) => {
            console.error(error);
          });
      },

      onNodeItemClick(item) {
        const _this = this;

        console.log("onNodeItemClick", item);
        if (item.entry.isFolder) {
          _this.$store.dispatch('setActiveNode', item.entry);
        } else {
          _this.clickedNode = item.entry;

          _this.popupActive = true;
          setTimeout(function () {
            _this.$refs['node-details'].getNode(item.entry.id);
          }, 200);

        }
      },

      onPageChange(params) {
        const _this = this;

        _this.isLoading = true;
        _this.serverParams.page = params;

        setTimeout(function () {
          _this.getActiveNodeContent();
        }, 1)

      },

      closeModal() {
        const _this = this;
        _this.popupActive = false;

        setTimeout(function () {
          _this.clickedNode = null;
        }, 200);


      }
    }
  }
</script>

